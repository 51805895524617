window.Axios = require('axios');
import {createApp} from 'vue';
// leaflet
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LMarker, LIcon, LPopup} from "@vue-leaflet/vue-leaflet";

//store
import store from './store';

//sass
import "@/sass/main.scss"

//components
import Navbar from "@/js/components/Navbar";
import Hamburger from "@/js/components/Hamburger";
import HeroImage from "@/js/components/HeroImage";
import Elma from "@/js/components/Elma";
import Slider from "@/js/components/Slider";
import Offices from "@/js/components/Offices";
import Pitch from "@/js/components/Pitch";
import ContactForm from "@/js/components/ContactForm";
import VueDragscroll from "vue-dragscroll";

//directives
import inViewport from "./directive/inViewport";
import btn from "./directive/btn";
import checkbox from "@/js/directive/checkbox";

const create = function (router) {
    const app = createApp({
        mounted() {
            this.$store.dispatch('setUp');
        }
    });
    if (!!router) {
        app.use(router)
    }
    app
            .use(store)
            .use(VueDragscroll)
            /*components*/
            .component('elma', Elma)
            .component('pitch', Pitch)
            .component('navbar', Navbar)
            .component('hamburger', Hamburger)
            .component('hero-image', HeroImage)
            .component('slider', Slider)
            .component('offices', Offices)
            .component('contact-form', ContactForm)
            .component('leaflet-map', LMap)
            .component('leaflet-layer', LTileLayer)
            .component('leaflet-marker', LMarker)
            .component('leaflet-icon', LIcon)
            .component('leaflet-popup', LPopup)
            /*direcrives*/
            .directive('in-viewport', inViewport)
            .directive('btn', btn)
            .directive('checkbox', checkbox)
    app.mount('#app');

};

if (process.env.NODE_ENV === 'development') {
    import('./router').then(module => {
        create(module.default)
    });
} else {
    create(false)
}
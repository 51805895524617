<script>
const inViewport = {
	beforeMount: (el, binding, values) => {
		let classes = typeof binding.value.class == 'string' ? [binding.value.class] : binding.value.class;

		function doIt() {
			classes.forEach(classItem => {
				el.classList.add(classItem)
			})
		}

		function handleIntersect(entries, observer) {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					observer.unobserve(el);
					doIt();
				}
			});
		}

		function createObserver() {
			const options = {
				root: null,
				threshold: 0
			};
			const observer = new IntersectionObserver(handleIntersect, options);
			observer.observe(el);
		}

		if (window["IntersectionObserver"]) {
			createObserver();
		}
	}
}

export default inViewport
</script>
<template>
	<div class="ham-cont" @click="hamClick()"
	     :class="[{'is-open': $store.getters.getNav}, {'is-closed': !$store.getters.getNav}]">
		<div class="hamburger-lines">
			<span class="line line1"></span>
			<span class="line line2"></span>
			<span class="line line3"></span>
		</div>
		<span class="text">{{ text }}</span>
	</div>
</template>
<script>
export default {
	name: 'hamburger',
	computed: {
		text() {
			return !this.$store.getters.getNav ? 'menu' : 'close';
		}
	},
	data() {
		return {}
	},
	methods: {
		hamClick() {
			this.$store.commit('toggleNav');
		}
	},
}
</script>

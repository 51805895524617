<template>
	<a href="/" class="logo animate__fadeInDown animate__animated">
		<img :src="$store.getters.getPaths.media +'/img/logo.svg'" alt="Reunion Ventures logo">
	</a>
	<hamburger class="animate__fadeIn animate__animated"></hamburger>
	<ul class="socials flex f-align-center animate__fadeInUp animate__animated">
<!--		<li class="mar-rl-4"><a href="#"><img :src="$store.getters.getPaths.media +'/img/icons/facebook.svg'" alt="facebook"></a></li>-->
<!--		<li class="mar-rl-4"><a href="#"><img :src="$store.getters.getPaths.media +'/img/icons/twitter.svg'" alt="twitter"></a></li>-->
<!--		<li class="mar-rl-4"><a href="#"><img :src="$store.getters.getPaths.media +'/img/icons/linkedin.svg'" alt="linkedin"></a></li>-->
		<li class="mar-rl-4"><a href="mailto:info@reunionventures.com"><img :src="$store.getters.getPaths.media +'/img/icons/mail.svg'" alt="mail"></a></li>
	</ul>
</template>

<script>

export default {
	name: 'NavBar',
	props: {}
}
</script>

<template>
	<div class="contact-form">
		<div class="success-message animate__animated"
		     :class="[{'animate__zoomIn': !loading && sent && success}, {'animate__zoomOut': loading || !sent || !success}]">
			<p class="fs-48 lh-10 color-beige capital">congrats! </p>
			<p class="fs-32 lh-144 color-white mar-top-32">You Are in, We will call you Soon!</p>
		</div>
		<form action="https://bewaarder.nl/api/forms/submit/rv_contact" method="post" class="form beige relative z-9"
		      @submit.stop.prevent="submitForm" ref="form">
			<div class="fields-grid">
				<div class="field">
					<input name="fieldF15F154" type="text" placeholder="First name" required>
				</div>
				<div class="field">
					<input name="fieldF15F157" type="text" placeholder="Last name" required>
				</div>

				<div class="field">
					<input name="fieldF15F155" type="email" placeholder="Email" required>
				</div>

				<div class="field">
					<input name="fieldF15F158" type="text" placeholder="Phone" required>
				</div>
				<div class="field field-100">
					<textarea name="fieldF15F156" placeholder="Your message" rows="5" required></textarea>
				</div>
<!--				<div class="field field-100">-->
<!--					<input type="checkbox" name="accept" v-checkbox id="accept"-->
<!--					       class="beige mar-right-8" required>-->
<!--					<label class="color-beige fs-16 lh-12" for="accept">I agree with Reunion Ventures terms and conditions.</label>-->
<!--				</div>-->
<!--				<div class="field field-100">-->
<!--				recaptcha-->
<!--				</div>-->
				<div class="messages-cont">
					<span class="sending animate__animated trans-3"
					      :class="[{'animate__fadeInLeft': loading}, {'animate__fadeOutLeft' : !loading}]">
							<span class="mr-1 fs-14 fw-700">Sending</span>
							<loading class="beige"></loading>
						</span>
				</div>
				<div class="field field-100 al-right">
					<button :class="[{'wait-loading' : loading}, {'success': success}, {'off': !loading && sent && success}]"
					        :disabled="loading"
					        class="trans-3 btn beige animate__animated">
						Lets go!
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Loading from "@/js/components/loading";

export default {
	name: "ContactForm",
	components: {Loading},
	data: () => {
		return {
			loading: false,
			success: false,
			sent: false,
		}
	},
	methods: {
		setUp() {

		},
		submitForm() {
			let data = new FormData(this.$refs.form);
			data.delete('accept')
			this.loading = true;
			Axios({
				header: {
					'Content-Type': 'application/json'
				},
				url: 'https://bewaarder.nl/api/forms/submit/rv_contact',
				method: 'POST',
				data: data
			}).then((res) => {
				console.log(res);
				this.loading = false;
				this.success = true;
				this.sent = true;
			}).catch((err) => {
				this.loading = false;
				this.sent = true;
				console.log(err)
			})
		},
	},
	mounted() {
		this.setUp();
	}
}
</script>
<script>
const btn = {
	beforeMount: (el, binding, values) => {
		let text = el.textContent.split(''),
				html = '<span class="btn-inner">';
		text.forEach(char => {
			if (char === ' ') {
				html += '<span class="space">' + char + '</span>';
			} else {
				html += '<span>' + char + '</span>';
			}
		})
		html += '</span>';
		el.innerHTML = html
	}
}

export default btn
</script>